export default async function () {
  const config = useRuntimeConfig()

  const host = await useGetHost()

  const token: any = useCookie('token')

  const authenticated = useAuthenticated()

  const userdata: any = useUserData()

  if (token.value) {
    const headers: any = {
      'x-app-domain': host,
      'Accept': 'application/json',
      'Authorization': `Bearer ${token.value}`,
    }

    await $fetch('/api/z2zl8edv1w', {
      baseURL: config.public.apiBase,
      method: 'POST',
      headers,
    }).catch(() => {
      token.value = null
      authenticated.value = false
      userdata.value = {}
    })
  }

  token.value = null
  authenticated.value = false
  userdata.value = {}
}
